/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import PropTypes from 'prop-types'
import CameraZoneCardStyled from './styledComponent'
import Button from '../Button/Loadable'
import CameraDetailScreenShot from '../CameraDetailScreenShot/Loadable'
import ZonePolygon from '../ZonePolygon/Loadable'
import Tooltip from '@material-ui/core/Tooltip'
import ExpandDownBtn from '../../asset/images/button/expand-down-btn.svg'
import CollapseUpBtn from '../../asset/images/button/collapse-up-btn.svg'
import * as PIXI from 'pixi.js'
import MESSAGE from './message'
import { convertZoneDataToCoordinatesXY, UNAUTHORIZED_EVENT, PERSON_TARGET, LIST_VEHICLE_CLASS, makeFirstLetterCapital } from '../../utils'

class CameraZoneCard extends React.Component {
  state = {
    zoneBottomCollapse: true
  }

  componentDidMount() {
    this.removePIXIConsole()
  }

  removePIXIConsole() {
    PIXI.utils.skipHello()
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  convertPointsDataArrayObjectXY(arrPoints) {
    const tempCoordinates = arrPoints.map(([x, y]) => new PIXI.Point(x, y))
    if (arrPoints.length > 0) {
      const [x, y] = arrPoints[0]
      tempCoordinates.push(new PIXI.Point(x, y))
    }
    return tempCoordinates
  }

  getGridColor(color) {
    const listColor = {
      '0xd0021b': this.getMessage('red_color'),
      '0x4a90e2': this.getMessage('blue_color'),
      '0x7ed321': this.getMessage('lightgreen_color'),
      '0xf5a623': this.getMessage('amber_color'),
      '0x7bc8a4': this.getMessage('medium_aqua_color'),
      '0xbd10e0': this.getMessage('purple_color'),
      '0x8b572a': this.getMessage('brown_color'),
      '0x50e3c2': this.getMessage('turquoise_color'),
      '0xf8e71c': this.getMessage('lemon_color'),
      '0x8fff14': this.getMessage('chartreuse_color')
    }
    return listColor[color] ? listColor[color] : 'not on the list'
  }

  generateListTargetColor(colorRanges) {
    if (colorRanges?.length > 0) {
      return colorRanges
        .map((colorRange) => {
          return colorRange.range_name || '-'
        })
        .join(', ')
    } else {
      return '-'
    }
  }

  getTargetOfEachZone(zone) {
    const targetClassName = zone.features[0] ? zone.features[0].class_type : '-'
    if (targetClassName === '') {
      return '-'
    }
    if (zone.violation_name === UNAUTHORIZED_EVENT) {
      return this.getMessage(targetClassName)
    } else {
      return this.generateListTargetColor(zone.color_range)
    }
  }

  sortCameraZones(zones) {
    const compare = (zoneA, zoneB) => {
      if (zoneA.zone_id < zoneB.zone_id) {
        return -1
      }
      if (zoneA.zone_id > zoneB.zone_id) {
        return 1
      }
      return 0
    }
    zones.sort(compare)
  }

  renderZoneRow() {
    const { zones } = this.props.data
    const tempZones = [...zones]
    this.sortCameraZones(tempZones)
    const output = tempZones.map((zone, index) => {
      const coordinates = this.convertPointsDataArrayObjectXY(zone.points)
      const violationName = this.getMessage(zone.violation_name)
      return (
        <div className="zone-row-wrapper" key={zone.zone_id}>
          <div className="zone-order">{index + 1}</div>
          <div className="zone-srcshot">
            <ZonePolygon
              zoneIterator={index}
              zoneData={zone}
              coordinates={coordinates}
              resolutionWidth={this.props.data.resolution_width}
              resolutionHeight={this.props.data.resolution_height}
            />
          </div>
          <div className="zone-detail-wrapper">
            <div className="zone-content">
              <div className="zone-subheader text-style">{this.getMessage('zone_subheader')}</div>
              <div className="zone-subcontent text-style">{zone.zone_id}</div>
            </div>
            <div className="zone-content">
              <div className="zone-subheader text-style">{this.getMessage('grid_color_subheader')}</div>
              <Tooltip title={this.getGridColor(zone.grid_color)} placement="bottom">
                <div className="zone-subcontent text-style">{this.getGridColor(zone.grid_color)}</div>
              </Tooltip>
            </div>
            <div className="zone-content">
              <div className="zone-subheader text-style">{this.getMessage('action_subheader')}</div>
              <div className="zone-subcontent text-style">{violationName}</div>
            </div>
            <div className="zone-content">
              <div className="zone-subheader text-style">{this.getMessage('target_subheader')}</div>
              <Tooltip title={this.getTargetOfEachZone(zone)} placement="bottom">
                <div className="zone-subcontent text-style prevent-text-overflow">{`${this.getTargetOfEachZone(zone)}`}</div>
              </Tooltip>
            </div>
          </div>
        </div>
      )
    })
    return output
  }

  expandDetail() {
    this.setState({
      zoneBottomCollapse: false
    })
  }

  collapseDetail() {
    this.setState({
      zoneBottomCollapse: true
    })
  }

  renderExpandButton() {
    return <img src={ExpandDownBtn} className="expand-btn" onClick={() => this.expandDetail()} />
  }

  renderCollapseButton() {
    return <img src={CollapseUpBtn} className="collapse-btn" onClick={() => this.collapseDetail()} />
  }

  renderZoneOfCamera() {
    return <div className="zones-wrapper">{this.renderZoneRow()}</div>
  }

  renderBottomZoneDetail() {
    if (this.state.zoneBottomCollapse) {
      return <div className="bottom-wrapper">{this.renderExpandButton()}</div>
    } else {
      return (
        <div className="bottom-wrapper expand">
          {this.renderZoneOfCamera()}
          {this.renderCollapseButton()}
          <div className="bottom-zone">&nbsp;</div>
        </div>
      )
    }
  }

  renderCameraScreenshot() {
    const coordinates = convertZoneDataToCoordinatesXY(this.props.data.zones)
    const listGridColors = this.props.data.zones.map((zone) => zone.grid_color)
    return (
      <div className="cam-src-wrapper">
        <CameraDetailScreenShot
          cameraData={this.props.data}
          coordinates={coordinates}
          listGridColors={listGridColors}
          imgResolutionWidth={this.props.data.resolution_width}
          imgResolutionHeight={this.props.data.resolution_height}
          imgSrc={this.props.data.image}
          containerWidth={190}
          containerHeight={130}
          showCameraStatusIcon
        />
      </div>
    )
  }

  renderCameraDotStatus() {
    const dotClassName = this.props.data.camera_status.toLowerCase()
    return <div className={'dot-status ' + dotClassName}>&nbsp;</div>
  }

  renderCameraStatus() {
    return this.props.data.camera_status.charAt(0) + this.props.data.camera_status.slice(1).toLowerCase()
  }

  onEditRuleClick(siteId, cameraId) {
    this.props.onEditRuleClick(siteId, cameraId)
  }

  shouldHideBottomZone() {
    return this.props.data.zones.length === 0
  }

  renderCameraZoneListDetail() {
    const { data } = this.props
    const noZoneClassName = this.shouldHideBottomZone() ? ' no-zone' : ''
    return (
      <div className="cam-detail-wrapper">
        <div className={'cam-content-top' + noZoneClassName}>
          <div className="cam-content">
            <div className="cam-subheader">{this.getMessage('title_subheader')}</div>
            <Tooltip title={data.name} placement="bottom">
              <div className="cam-subcontent camera-title prevent-text-overflow">{data.name}</div>
            </Tooltip>
          </div>
          <hr className="vertical-line" />
          <div className="cam-content">
            <div className="cam-subheader">{this.getMessage('cam_id_subheader')}</div>
            <div className="cam-subcontent">{data.camera_id}</div>
          </div>
          <hr className="vertical-line" />
          <div className="cam-content">
            <div className="cam-subheader">{this.getMessage('zone_subheader')}</div>
            <div className="cam-subcontent">{data.zones.length}</div>
          </div>
          <hr className="vertical-line" />
          <div className="cam-content">
            <div className="cam-subheader">{this.getMessage('status_subheader')}</div>
            <div className="cam-subcontent">
              {this.renderCameraDotStatus()}
              {this.renderCameraStatus()}
            </div>
          </div>
          <hr className="vertical-line" />
          <div className="edit-rule-wrapper">
            <Button
              className="edit-rule-btn"
              onClick={() => this.onEditRuleClick(data.site_id, data.camera_id)}
              text={this.getMessage('edit_rule_btn_text')}
              disabled={this.props.isUser}
            />
          </div>
        </div>
        {!this.shouldHideBottomZone() && this.renderBottomZoneDetail()}
      </div>
    )
  }

  renderContent() {
    let output = null
    if (this.props.data) {
      output = (
        <div className="cam-zone-container">
          {this.renderCameraScreenshot()}
          {this.renderCameraZoneListDetail()}
        </div>
      )
    }
    return output
  }

  render() {
    return <CameraZoneCardStyled>{this.renderContent()}</CameraZoneCardStyled>
  }
}

CameraZoneCard.defaultProps = {
  language: 'EN',
  isUser: true
}

CameraZoneCard.propTypes = {
  data: PropTypes.shape({
    area_id: PropTypes.number,
    area_name: PropTypes.string,
    camera_id: PropTypes.number,
    camera_status: PropTypes.string,
    gps: PropTypes.string,
    health_check_time: PropTypes.string,
    image: PropTypes.string,
    is_active: PropTypes.bool,
    is_vehicle_detection: PropTypes.bool,
    motion_threshold: PropTypes.number,
    name: PropTypes.string,
    num_grids_threshold: PropTypes.number,
    reading_fps: PropTypes.number,
    resolution_width: PropTypes.number,
    resolution_height: PropTypes.number,
    rtsp_address: PropTypes.string,
    scale_factor: PropTypes.number,
    site_id: PropTypes.number,
    target_classes: PropTypes.array,
    vehicle_classes: PropTypes.array,
    violation_count: PropTypes.number,
    violation_time: PropTypes.number,
    project_id: PropTypes.number,
    zones: PropTypes.array
  }),
  language: PropTypes.string,
  onEditRuleClick: PropTypes.func,
  isUser: PropTypes.bool
}

export default CameraZoneCard
