const MESSAGE = {
  EN: {
    title_subheader: 'TITLE',
    cam_id_subheader: 'CAMERA ID',
    zone_subheader: 'ZONE',
    status_subheader: 'STATUS',
    grid_color_subheader: 'GRID COLOR',
    action_subheader: 'DETECTION TYPE',
    target_subheader: 'TARGET',
    edit_rule_btn_text: 'Edit Rules',
    edit_btn_text: 'Edit',
    cancel_btn_text: 'Cancel',
    red_color: 'Red',
    blue_color: 'Blue',
    lightgreen_color: 'Lightgreen',
    amber_color: 'Amber',
    medium_aqua_color: 'Medium Aquamarine',
    purple_color: 'Purple',
    brown_color: 'Brown',
    turquoise_color: 'Turquoise',
    lemon_color: 'Lemon',
    chartreuse_color: 'Chartreuse',
    Unauthorized: 'Unauthorized',
    'Color Violation': 'Color Violation',
    person: 'Person',
    truck: 'Truck'
  },
  TH: {
    title_subheader: 'ชื่อกล้อง',
    cam_id_subheader: 'ID กล้อง',
    zone_subheader: 'โซน',
    status_subheader: 'สถานะกล้อง',
    grid_color_subheader: 'สีของโซน',
    action_subheader: 'ประเภทการตรวจพบ',
    target_subheader: 'เป้าหมาย',
    edit_rule_btn_text: 'แก้ไขกฎ',
    edit_btn_text: 'แก้ไข',
    cancel_btn_text: 'ยกเลิก',
    red_color: 'แดง',
    blue_color: 'น้ำเงิน',
    lightgreen_color: 'เขียวอ่อน',
    amber_color: 'อำพัน',
    medium_aqua_color: 'เขียวน้ำทะเล',
    purple_color: 'ม่วง',
    brown_color: 'น้ำตาล',
    turquoise_color: 'เขียวขุ่น',
    lemon_color: 'มะนาว',
    chartreuse_color: 'เขียวเหลือง',
    Unauthorized: 'ไม่อนุญาตให้เข้า',
    'Color Violation': 'สี',
    person: 'บุคคล',
    truck: 'รถบรรทุก'
  }
}

export default MESSAGE
