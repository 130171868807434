import styled from 'styled-components'

export default styled.div`
  width: 100%;
  position: relative;
  .cam-zone-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    .cam-src-wrapper {
      background: ${(props) => props.theme.darkBackground};
      width: 190px;
      height: 130px;
      border-radius: 5px;
    }
    .cam-detail-wrapper {
      width: calc(100% - 210px);
      display: flex;
      flex-direction: column;
      background-color: white;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      .cam-content-top {
        min-height: 107px;
        height: 107px;
        display: flex;
        .cam-content {
          height: 100%;
          width: 209px;
          max-width: 209px;
          padding-top: 22px;
          padding-left: 20px;
          .cam-subheader {
            font-family: 'Prompt', sans-serif;
            font-weight: bold;
            font-size: 10px;
            line-height: 0.63;
            color: ${(props) => props.theme.darkBackground};
            opacity: 0.5;
          }
          .cam-subcontent {
            font-family: 'Prompt', sans-serif;
            font-weight: bold;
            font-size: 34px;
            color: ${(props) => props.theme.darkBackground};
            margin-top: 8px;
            display: flex;
            align-items: center;
            .dot-status {
              width: 15px;
              height: 15px;
              border-radius: 100%;
              margin-right: 10px;
              &.active {
                background-color: ${(props) => props.theme.onlineCamera};
              }
              &.inactive {
                background-color: ${(props) => props.theme.inactiveCamera};
              }
              &.offline {
                background-color: ${(props) => props.theme.offlineCamera};
              }
            }
            &.camera-title {
              display: block;
              width: 175px;
            }
          }
        }
        .vertical-line {
          height: 80px;
          width: 1px !important;
          color: ${(props) => props.theme.greyDisabled};
          opacity: 0.5;
          margin: 0;
          position: relative;
          top: 10px;
        }
        .edit-rule-wrapper {
          display: flex;
          align-items: center;
          width: 199px;
          justify-content: center;
          .edit-rule-btn {
            width: 160px;
            font-size: 14px;
            button {
              width: 160px !important;
            }
          }
        }
        &.no-zone {
          .cam-content {
            padding-top: 30px;
          }
          .vertical-line {
            height: 90px;
            top: 20px;
          }
          .edit-rule-wrapper {
            padding-top: 20px;
          }
        }
      }
      .bottom-wrapper {
        border-top: 1px solid ${(props) => props.theme.greyDisabled};
        height: calc(100% - 107px);
        position: relative;
        .expand-btn {
          position: absolute;
          left: 50%;
          bottom: 9px;
          &:hover {
            cursor: pointer;
          }
        }
        .collapse-btn {
          position: absolute;
          left: 50%;
          bottom: 9px;
          &:hover {
            cursor: pointer;
          }
        }
        &.expand {
          position: relative;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
          .zones-wrapper {
            min-height: 45px;
            max-height: 209px;
            background-color: #f5fff8;
            border-bottom: 1px solid ${(props) => props.theme.greyDisabled};
            display: flex;
            flex-direction: column;
            overflow: auto;
            padding-top: 27px;
            .zone-row-wrapper {
              display: grid;
              grid-template-columns: 5% 10% 85%;
              grid-auto-rows: 45px;
              margin-bottom: 20px;
              .zone-order {
                place-self: center;
                font-family: 'Prompt', sans-serif;
                font-weight: bold;
                font-size: 24px;
              }
              .zone-srcshot {
                .src-wrapper {
                  width: 65px;
                  height: 100%;
                  border-radius: 3px;
                  background-color: ${(props) => props.theme.darkBackground};
                }
              }
              .zone-detail-wrapper {
                display: grid;
                grid-template-columns: 10% 17% 20% 40%;
                .zone-content {
                  padding-top: 4px;
                  color: ${(props) => props.theme.darkBackground};
                  .zone-subheader {
                    font-size: 10px;
                    font-weight: bold;
                    line-height: 0.63;
                    opacity: 0.5;
                  }
                  .zone-subcontent {
                    font-size: 18px;
                    font-weight: bold;
                    width: 140px;
                    .tooltip-container {
                      padding: 10px;
                      font-family: 'Prompt', sans-serif;
                      font-size: 16px;
                      font-weight: normal;
                      font-style: normal;
                      font-stretch: normal;
                      line-height: 1.25;
                      letter-spacing: normal;
                    }
                  }
                }
              }
            }
          }
          .bottom-zone {
            min-height: 23px;
            height: 23px;
            background-color: white;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
  }
`
